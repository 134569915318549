<template>
  <div class="photo-share">
    <Project :currentProject="currentProject"/>
  </div>
</template>

<script>
import Project from '@/components/Project.vue'
import ProjectStore from '@/stores/ProjectStore.js'

export default {
  name: "PhotoShare",
  components: {
    Project,
  },
  data() {
    return {
      currentProject: ProjectStore.projects.find((x) => x.title === 'Photo Share')
    }
  }
}
</script>