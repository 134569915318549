<template>
  <div class="project">
    <Hero :heading="currentProject.title" class="Hero" />
    <div class="content">
      <div class="outline">
        <div class="top">
          <p class="subTitle">Role</p>
          <p class="subDescripiton">{{ currentProject.role }}</p>
        </div>
        <div class="top">
          <p class="subTitle">Date</p>
          <p class="subDescripiton">{{ currentProject.date }}</p>
        </div>
        <div class="top">
          <p class="subTitle">Tech</p>
          <p class="subDescripiton">{{ currentProject.tech }}</p>
        </div>
      </div>
      <div class="videoContainer">
        <a :href="`${currentProject.siteURL}`" target="_blank">
          <img
            :src="require(`@/assets/${currentProject.imageName}`)"
            :alt="`${currentProject.title} image/video`"
            id="video"
          />
        </a>
      </div>
      <div class="description">
        <div class="top">
          <p class="subTitle">Description</p>
          <p class="subDescripiton">{{ currentProject.descriptionLong }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hero from "@/components/Hero.vue";

export default {
  name: "Project",
  components: {
    Hero,
  },
  props: {
    currentProject: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.content {
  width: 70vw;
  margin: 0 auto;
}

.outline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.top {
  padding-bottom: 0.5em;
}
.subTitle {
  line-height: 0em;
  font-family: var(--primary-ff);
  font-weight: 500;
  font-size: var(--fs-3);
  padding-bottom: 0.1em;
}
.subDescripiton {
  font-family: var(--secondary-ff);
  font-weight: 400;
  font-size: var(--fs-2);
}

.videoContainer {
  margin: 0 auto;
  height: auto;
  width: 100%;
  outline: none;
  margin-top: 0.5em;
  border-radius: var(--br);
  padding: 2em 0;
}
#video {
  margin: 0 auto;
  width: 100%;
  height: auto;
  border: 3.5px solid var(--color-title);
}

.description {
  padding-top: 0.5em;
}
.description .subDescripiton {
  padding: 0 1.5em;
}

img {
  filter: blur(0px);
  opacity: 1;
}

/* Mobile */
@media (max-width: 400px) {
  .top .subTitle {
    line-height: 1em;
    font-size: var(--fs-2m);
    padding-bottom: 0.1em;
  }

  .top .subDescripiton {
    font-size: var(--fs-1m);
    padding: 0;
    margin: 0;
  }

  .videoContainer {
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }
  #video {
    margin: 0 auto;
    width: 100%;
  }
  .content .description .subDescripiton {
    padding: 0;
  }
}

/* Tablet */
@media (max-width: 800px) {
  .content {
    width: 100%;
  }

  .outline {
    flex-direction: column;
    padding-bottom: 1.5em;
  }

  p {
    margin: 0;
  }

  .subTitle {
    line-height: 1em;
    font-family: var(--primary-ff);
    font-weight: 500;
    font-size: var(--fs-3);
    padding-bottom: 0.1em;
  }
  .subDescripiton {
    font-family: var(--secondary-ff);
    font-weight: 400;
    font-size: var(--fs-2);
    padding: 0 1em;
  }

  .videoContainer {
    margin: 0 auto;
    width: 100%;
  }
  #video {
    margin: 0 auto;
    width: calc(100% - 3.5px * 2);
  }

  .description {
    padding-top: 1.5em;
  }
  .description .subDescripiton {
    padding: 0 1em;
  }
}

/* Desktop L */
@media (min-width: 1300px) {
  .videoContainer {
    margin: 0 auto;
    width: 70%;
  }
  #video {
    margin: 0 auto;
    width: 100%;
  }
}
</style>